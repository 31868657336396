<template>
  <div>
    <el-main>
      <search-node-template @submit="submitSearch"/>

      <div class="table-btn-list">
        <el-button
            class="table-btn"
            type="primary"
            @click="showNewModal"
        >
          新增
        </el-button>
      </div>

      <el-table v-loading="loading" :data="node_templateList">
        <el-table-column width="100">
          <template #default="scope">
            <div style="display:flex;align-items:center;justify-content:center">
              <el-image style="width: 49px; height: 49px;" :src="backImgUrl" fit="contain"/>
              <el-image style="width: 23px; height: 22px;position:absolute" :src="imgUrl" fit="contain"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-row :span="24">
              <el-col :span="12">
                <span class="title">{{ scope.row.name }}</span>
              </el-col>
              <el-col :span="12" style="text-align:right">
                <el-tooltip content="节点详情">
                  <el-icon :size="20" style="margin-right:10px" @click="showNode(scope.row)">
                    <Tickets color="#00B7FF" />
                  </el-icon>
                </el-tooltip>

                <el-tooltip content="编辑">
                  <el-icon :size="20" style="margin-right:10px" @click="showEditModal(scope.row)">
                    <Edit color="#00B7FF" />
                  </el-icon>
                </el-tooltip>

                <el-tooltip content="删除">
                  <el-icon :size="20" color="#ff0000" @click="handleDelete(scope.row)">
                    <Delete/>
                  </el-icon>
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row :span="24">
              <el-col :span="8">
                <el-descriptions :column="1">
                  <el-descriptions-item label="项目类型：">{{ scope.row.projectType?.name }}</el-descriptions-item>
                </el-descriptions>
              </el-col>
              <el-col :span="8">
                <el-descriptions :column="1">
                  <el-descriptions-item label="节点数量：">
                    <span @click="showNode(scope.row)" style="color: #409eff;">{{ scope.row.nodeCount }}</span>
                  </el-descriptions-item>
                </el-descriptions>
              </el-col>
              <el-col :span="8">
                <el-descriptions :column="1">
                  <el-descriptions-item label="项目数量：">{{ scope.row.projectCount }}</el-descriptions-item>
                </el-descriptions>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>

      <pagination
          v-show="total>0"
          :total="total"
          v-model:page="query.pageNum"
          v-model:limit="query.pageSize"
          @pagination="fetchData"
      />

      <!-- 新增 模态框 -->
      <new-node-template
          v-if="isShowNewModal"
          v-model:visible="isShowNewModal"
          @completed="fetchData"
      />

      <!-- 编辑 模态框 -->
      <edit-node-template
          v-if="isShowEditModal"
          :id="editingId"
          v-model:visible="isShowEditModal"
          @completed="fetchData"
      />

      <node-index
          v-if="isShowNodeModal"
          :node-template-id="detailId"
          v-model:visible="isShowNodeModal"
          @completed="fetchData"
      />
    </el-main>
  </div>
</template>

<script>
import {delNodeTemplate, listNodeTemplate} from '@/api/node_template'
import {isResOK} from '@/api/response'
import {defineAsyncComponent} from 'vue'
import SearchNodeTemplate from '@/view/node_template/Search.vue'
import Pagination from "@/components/Pagination/index.vue";
import store from "@/store";
import {getUrlParams} from "@/api/userList";
import {getCurrentUser} from "@/api/user";

export default {
  name: 'NodeTemplateList',
  components: {
    Pagination,
    SearchNodeTemplate,
    NewNodeTemplate: defineAsyncComponent(() => import('@/view/node_template/New.vue')),
    EditNodeTemplate: defineAsyncComponent(() => import('@/view/node_template/Edit.vue')),
    NodeIndex: defineAsyncComponent(() => import('@/view/node/index.vue')),
  },

  data() {
    return {
      data: [],
      loading: true,
      total: 0,

      query: {
        pageNum: 1,
        pageSize: 10,
      },

      sort: {
        sort_field: '',
        sort_order: '',
      },

      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowNodeModal: false, // 是否显示节点表单弹窗
      editingId: 0, // 正在编辑的记录id
      detailId: 0,
      // 节点模版表格数据
      node_templateList: [],
      imgUrl: require(`/src/assets/node_template.png`),
      backImgUrl: require(`/src/assets/project_back.svg`),
    }
  },

  computed: {},

  created() {
    this.fetchData()
    this.fetchCurrentUser()
  },

  methods: {
    fetchCurrentUser() {
      store.dispatch('user_info/cleanUser')
      let data = {}
      const params = getUrlParams(window.location.href)
      if (params[`accessToken`]) {
        sessionStorage.setItem('token', params[`accessToken`])
        data[`auth-token`] = params[`accessToken`]
        getCurrentUser(data).then(res => {
          if (isResOK(res)) {
            store.dispatch('user_info/setUser', res.data)
          }
        })
      }
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(row) {
      this.editingId = row.id
      this.isShowEditModal = true
    },

    showNode(row) {
      this.detailId = row.id
      this.isShowNodeModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
          {pageNum: this.query.pageNum},
          search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      listNodeTemplate(this.query).then(response => {
        this.node_templateList = response.rows
        this.total = response.total
        this.loading = false;
      }).finally(() => {
        this.loading = false
      })
    },

    handleDelete(row) {
      const id = row.id
      this.$messageBox.confirm(
          '是否确认删除节点模版"' + row.name +'"',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        delNodeTemplate(id, { operatingMode: 'web' }).then((res) => {
          if (isResOK(res)) {
            this.$message({
              message: '数据删除成功',
              type: 'success',
            })
          }
          this.fetchData()
        })
      }).catch(() => {
      })
    },

  },
}
</script>
<style scoped lang="less">
.title {
  font-size: 17px;
  color: #000000;
  font-weight: bold;
}
</style>
