<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="85px" label-position="left">
      <el-row>
        <el-col :span="6">
          <el-form-item label="模版名称" prop="name">
            <el-input
                style="width: 240px"
                v-model="queryParams.name"
                placeholder="请输入模版名称"
                clearable
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="项目类型" prop="projectType">
            <el-select
                v-model="queryParams.projectTypeId"
                placeholder="请选择项目类型"
                @focus="getProductTypeOptions"
                style="width: 240px"
            >
              <el-option
                  v-for="item in productTypeOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="handleQuery">搜索</el-button>
            <el-button type="warning" @click="resetQuery">刷新</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {getProjectTypeOptions} from "@/api/project_type";
import {isResOK} from "@/api/response";

export default {
  name: 'SearchNodeTemplate',
  emits: ['submit'],

  data() {
    return {
      queryParams: {
        name: null,
        projectTypeId: null,
        nodeCount: null,
        projectCount: null,
      },
      productTypeOptions: [],
    }
  },

  methods: {
    getProductTypeOptions() {
      getProjectTypeOptions().then((res) => {
        if (isResOK(res)) {
          this.productTypeOptions = res.data;
        }
      });
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.$emit('submit', this.queryParams)
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.queryParams = {}
      this.$emit('submit', this.queryParams)
    },
  },
}
</script>
